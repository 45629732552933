<template>
  <div class="about">
    <div class="banner">
      <img src="@/assets/img/about/banner.png" />
    </div>
    <div class="mainContent">
      <div class="introduce">
        <AboutTitle :title="'关于我们'" />
        <div class="content_wrapper">
          <div class="introBox">
            <img src="@/assets/img/about/intro_left.png" />
            <div class="intro_right">
              <p class="title">企业简介 COMPANY PROFILE</p>
              <p class="content">德卡云创科技（西安）有限公司（简称：德卡云创，英文简称：DCC）是德卡科技成员之一、是持续成长的智慧医疗、智慧医保及智慧社保综合解决方案服务商，公司位于西安高新技术产业开发区，以信息化发展战略为指导，注重科研与应用相结合。公司始终洞察时代发展趋势，探索软硬件技术与场景化应用的创新，赋能全国数家大中型客户实现信息化、数字化、智慧化发展，业务涉及：智慧医院服务、全流程就医服务、一站式床旁结算及床旁入院服务、智慧医保服务、医保“村医通”服务、数字人社服务以及配套的终端产品服务等。</p>
            </div>
          </div>
          <div class="middle">
            <!-- <div class="null_box"></div> -->
            <div class="middle_left">
              德卡云创坚持将智能终端产品与科技完美结合，为合作伙伴提供高品质、高质量的技术和服务支撑，公司拥有数项软件著作权证书及专利证书，通过了CMMI3级国际认证，技术实力和服务能力受业界广泛认可的高新技术企业。<br />
              公司积极置身于社会数字化转型中，创新利民便民服务、不断提升行业数字化生态水平，推动涉及领域信息化迈向数字化转型新时代，为客户和社会创造价值。同时，公司以互联网+智能终端为载体，始终坚定不移地推动我国医保、社保及医疗政策的落地，为用户提供主动式、智能化、全流程的智慧服务，为医院提供一站式智慧解决方案，为社保提供“社保一卡通”应用场景的创新服务，为国家医保部门加强医保三电子两支付应用提供有力的技术支撑。
            </div>
            <img src="@/assets/img/about/middle_right.png" />
            <div class="middle_right"></div>
          </div>
          <div class="bottom">面向未来，德卡云创将坚定不移地秉持“专注专业、高效创新、开放协作、敬业拼搏”的核心价值观，持续以创新赋能我们的客户和社会；以和谐共生、共享利他的理念，建立从患者、医院到企业、供应商、友商的伙伴式生态模式；以“产学研用”协同共进的策略，推动智慧医疗、智慧医保及数字社保服务等软硬件一体化应用的发展，以主动服务为先导，不断突破场景应用创新，持续成长，成为用户值得信赖、有社会责任感的企业而奋斗！</div>
          <!-- 第二版 -->
          <!-- <div class="introBox">
            <div class="left">
              <h4 class="title">关于我们</h4>
              <p class="title_en">COMPANY PROFILE</p>
              <div class="left_content">德卡云创科技（西安）有限公司（简称：德卡云创，英文简称：DCC）是德卡科技成员之一、是持续成长的智慧医疗、智慧医保及智慧社保综合解决方案服务商，公司位于西安高新技术产业开发区，以信息化发展战略为指导，注重科研与应用相结合。公司始终洞察时代发展趋势，探索软硬件技术与场景化应用的创新，赋能全国数家大中型客户实现信息化、数字化、智慧化发展，业务涉及：智慧医院服务、全流程就医服务、一站式床旁结算及床旁入院服务、智慧医保服务、医保“村医通”服务、数字人社服务以及配套的终端产品服务等。</div>
            </div>
            <img src="@/assets/img/about/intro_right.png" alt="" />
          </div> -->
          <!-- <p>
            <span>德卡云创科技(西安)有限公司</span>(简称DCC) 是德卡科技成员企业之一 ， 设立于西安高新技术产业开发区 ，公司以国家信息化发展战略为指导， 注重科研与应用相结合， 致力于将智能终端产品与科技完美融合， 为客户提供高质、 高效的解决方案及服务 。 公司产品主要服务于医保、社保、卫生、政务、交通、金融、高校等领域 。 硬件产品涉及： 智能卡读写终端、医保业务综合服务终端、人脸识别通行终端、 智慧医疗终端、 社保应用终端、 身份识别终端等 。 软件产品涉及：智慧医疗服务应用软件、互联网医院软件平台、医保终端应用、社会保障卡公共服务“ 一卡通 ”拓展应用软件、实名制人脸通行应用软件和政务预约应用软件等 。软件兼容性强，可原生支持在国产Linux系统和Windows系统上直接运行 ; 整体系统架构具备高可用 ， 高性能的弹性系统架构设计以及使用现代前沿的面向对象开发语言诸如Java、C#； 所有数据交互必须经过RSA非对称加密标准，
            保证客户端和服务端的数据传输安全和完整性 。公司以互联网+智能终端为载体 ， 以全力支撑信息化建设为己任， 集开发、 生产、 销售、服务和软硬件一体化的双软、高新技术企业 。
          </p>
          <p>德卡专注于技术研发和创新， 拥有多项国家技术专利和著作权证书100多项 ， 其中外观专利77项、软件著作权证书24项、软件产品登记证书14项、实用新型11项、发明专利4项 。</p>
          <p>德卡不断从社会各界吸收优秀的管理及技术研发人才，建立了一支由资深行业顾问、研发工程师、测试工程师、实施工程师所组成的专业的顾问和技术团队，为用户提供规范化、个性化、专业化的服务。我们坚持积极创新及周到服务的企业风格，以不断创新为使命，以主动服务为先导，不断突破创新，让德卡云创更多、更好、更专业的应用及产品服务于各行各业！</p>
          <div>
            <img src="@/assets/img/about/dcc_company.png" alt="" />
            <img src="@/assets/img/about/dcc_company_shenzhen.png" alt="" />
          </div> -->
        </div>
      </div>
      <!-- 专利证书 -->
      <!-- <div class="patentCertificate">
        <AboutTitle :title="'国家技术专利和著作权证书'" />
        <div class="pat_content">
          <div class="pat_content_item" v-for="v in pat_cer_Data" :key="v.url">
            <img :src="v.url" />
            <p class="num">{{ v.num }}</p>
            <p>{{ v.lable }}</p>
          </div>
        </div>
      </div> -->
      <div class="corporateCulture">
        <span class="title">企业文化</span>
        <div class="subTitle">经营理念</div>
        <p>专业、专注 、创新</p>
        <div class="subTitle">企业理念</div>
        <p>注重科研和应用相结合，利用互联网+平台，将智能终端产品与软件完美结合，为用户提供高质高效的解决方案及服务。</p>
      </div>
      <div class="honor">
        <AboutTitle :title="'荣誉资质'" />
        <img src="@/assets/img/about/honor.png" alt="" />
        <img src="@/assets/img/about/honor2.png" alt="" />
      </div>
      <div class="partner">
        <AboutTitle :title="'合作关系'" />
        <img src="@/assets/img/about/partner.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import AboutTitle from '@/components/aboutTitle'
export default {
  components: { AboutTitle },
  data() {
    return {
      pat_cer_Data: [
        {
          url: require('@/assets/img/about/wgzl.svg'),
          num: '77',
          lable: '外观专利',
        },
        {
          url: require('@/assets/img/about/rjzzqzs.svg'),
          num: '24',
          lable: '软件著作权证书',
        },
        {
          url: require('@/assets/img/about/rjcpdjzs.svg'),
          num: '14',
          lable: '软件产品登记证书',
        },
        {
          url: require('@/assets/img/about/syxx.svg'),
          num: '11',
          lable: '实用新型',
        },
        {
          url: require('@/assets/img/about/fmzl.svg'),
          num: '14',
          lable: '发明专利',
        },
      ],
    }
  },
  methods: {},
  created() {},
  mounted() {},
}
</script>
<style scoped lang="less">
.about {
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #fff;
  .banner {
    width: 100%;
    height: 3.9rem;
    position: relative;
    background: #eceaec;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .mainContent {
    width: 100%;
    display: flex;
    background: #f6f7fb;
    flex-direction: column;
    & > * {
      padding: 0 3.57rem;
    }
    .introduce {
      padding: 0.6rem 0;
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      .content_wrapper {
        width: 12rem;
        margin-top: 0.6rem;
        margin-bottom: 0.42rem;
        display: flex;
        flex-direction: column;
        .introBox {
          display: flex;
          justify-content: space-between;
          img {
            display: block;
            width: 4.21rem;
            height: 2.06rem;
            margin-top: 0.6rem;
          }
          .intro_right {
            width: 6.3rem;
            height: 2.07rem;
            .title {
              font-size: 0.24rem;
              font-weight: 600;
            }
            .content {
              font-size: 0.16rem;
              color: #333333;
              margin-top: 0.2rem;
              line-height: 0.32rem;
            }
          }
        }
        .middle {
          margin-top: 0.6rem;
          display: flex;
          position: relative;
          justify-content: flex-end;
          .middle_left {
            width: 8.46rem;
            // height: 2.57rem;
            background: rgba(238, 238, 238, 0.72);
            border-radius: 0rem 0rem 0rem 0rem;
            padding: 0.48rem;
            font-size: 0.16rem;
            line-height: 0.32rem;
            position: absolute;
            top: 1.2rem;
            right: 2.6rem;
          }
          img {
            width: 3.59rem;
            height: 5.34rem;
            display: block;
            margin-left: 7rem;
          }
        }
        .bottom {
          width: 11rem;
          margin: 0.6rem auto 0;
          font-size: 0.16rem;
          color: #333333;
          line-height: 0.32rem;
        }
        // .introBox {
        //   margin-top: 0.3rem;
        //   width: 100%;
        //   display: flex;
        //   justify-content: space-around;
        //   .left {
        //     width: 6.2rem;
        //     font-size: 0.16rem;
        //     color: #333333;
        //     line-height: 0.3rem;
        //     .title {
        //       font-size: 0.24rem;
        //       font-weight: 600;
        //       color: #333333;
        //       margin-bottom: 0.1rem;
        //     }
        //     .title_en {
        //       margin-bottom: 0.2rem;
        //     }
        //     .left_content {
        //       color: #666666;
        //       text-indent: 0.2rem;
        //     }
        //   }
        //   img {
        //     display: block;
        //     width: 4.21rem;
        //     height: 2.06rem;
        //     margin-top: 0.5rem;
        //   }
        // }
      }
    }
    .patentCertificate {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .pat_content {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin: 0.51rem 0;
        .pat_content_item {
          text-align: center;
          img {
            width: 0.5rem;
            height: 0.5rem;
            display: block;
            margin: 0 auto;
          }
          p {
            font-size: 0.18rem;
            color: #333333;
          }
          .num {
            font-size: 0.28rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #00a4ea;
            margin: 0.2rem 0;
          }
        }
      }
    }
    .corporateCulture {
      // height: 3.8rem;
      background-image: url('../assets/img/about/background.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      // padding-top: 0.78rem;
      box-sizing: border-box;
      .title {
        color: #ffffff;
        font-size: 0.33rem;
      }
      .subTitle {
        width: 1.18rem;
        height: 0.25rem;
        font-size: 0.18rem;
        font-weight: bold;
        color: #1d7fab;
        text-align: center;
        background-image: url('../assets/img/about/subtitle_bg.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        &:nth-child(1) {
          margin-top: 0.44rem;
        }
      }
      p {
        font-size: 0.18rem;
        color: #ffffff;
        margin: 10px 0 20px;
        line-height: 0.36rem;
        width: 60%;
        letter-spacing: 0.02rem;
      }
    }
    .honor,
    .partner {
      display: flex;
      flex-direction: column;
      align-items: center;
      // background: #fff;
      padding: 0.5rem 3.57rem;
      img {
        width: 100%;
        height: auto;
        margin-top: 0.4rem;
      }
    }
    .honor {
      background: #fff;
    }
  }
}
</style>
